import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { VHContainer } from "../components/sections"
import { colors } from "../components/theme"

const Container = styled(VHContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: ${colors.greymid};
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nije pronađeno" />
    <Container>
      <h1>404: Nije pronađeno</h1>
      <p>Ups! Izgleda da se ova stranica izgubila.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
